<template>
  <div class="affform">
  <h1><img alt="logo" class="logo" :src='"../../assets/logo.png"'/>{{ msg }}</h1>

         <v-select
         class="month-select"
            :items="dates"
            :disabled="isLoading"
            :loading="isLoading"
            v-model="selectedDate"
            item-text="text"
            @change="onDateChange"
            single-line
            outlined
            solo
            return-object
                dense
                hide-details
        >
        
        </v-select>

        <v-data-table
    :headers="headers"
    :items="results"
    :items-per-page="100"
    :loading="isLoading"
      :options.sync="options"
      @update:options="updateSort"
    class="elevation-1"
  ></v-data-table>


    <!-- <div v-if="account.type === 'admin'" v-for="(account, index) in results" :key="index">
      <h1 v-if="account.email">{{account.email}}  -  {{account.platform}}</h1>
      <h1 v-if="!account.email">{{account.platform}}</h1>
      <h4>Registered: {{account.registered}}</h4>
      <h4>Basic Credits: {{account.basicCredit}}<span v-if="account.type === 'admin'">, Basic Total:{{account.basicCredit*8}}</span></h4>
      <h4>Premium Subscribers: {{account.premium}}<span v-if="account.type === 'admin'">, Premium Credits: {{account.premiumCredit}}, Premium Total:{{account.premium*12+account.premiumCredit*6}}</span></h4>
      <h4>Corp Subscribers: {{account.corp}}<span v-if="account.type === 'admin'">, Corp Credits: {{account.corpCredit}}, Premium Total:{{account.corp*69+account.corpCredit*3}}</span></h4>
      <h4 v-if="account.type === 'admin'">Monthly Total: {{account.basicCredit*8 + account.premium*12+account.premiumCredit*6 + account.corp*69+account.corpCredit*3}}</h4>
    </div> -->
  </div>
</template>

<script>

import systemConnection from '@/connections/system.service'

export default {
  name: "affform",
  data: function () {
    return {
      headers: [
          { text: 'Platforms(s)', value: 'platform' },
          {
            text: 'New Register(s)',
            value: 'registered',
          },
          { text: '60 Minutes', value: 'basicCredit' },
          { text: 'Premium Subscriber(s)', value: 'premium' },
          { text: 'Bulk Subscriber(s)', value: 'corp' }
        ],
      msg2: "Select your month",
      results:[],
      dates:[{text:'Please select...',value:''}],
      selectedDate:{text:'Please select...',value:''},
      isLoading: false,
      options: {
        sortBy: [],
        sortDesc: [], // This controls the sort direction
      },
    };
  },
  methods: {
    onDateChange(dataObject){
      const me = this
      me.reset();
      me.isLoading = true;
      systemConnection.getDetailsByMonth(dataObject.value).then(function(results){
        if(results.data && results.data[0] && results.data[0].type === 'admin'){
          results.data = results.data.map(function(d){
            d.monthlyTotal = d.basicCredit*8 + d.premium*12+d.premiumCredit*6 + d.corp*69 + d.corpCredit*3;
            d.basicTotal = d.basicCredit*8;
            d.premiumTotal = d.premium*12+d.premiumCredit*6;
            d.bulkTotal = d.corp*69+d.corpCredit*3;
            return d;
          });

          me.headers = [
          { text: 'Email', value: 'email' },
          { text: 'Platforms(s)', value: 'platform' },
          {
            text: 'New Register(s)',
            value: 'registered',
          },
          { text: '60 Minutes', value: 'basicCredit' },
          { text: 'Premium Subscriber(s)', value: 'premium' },
          { text: 'Premium Credit(s)', value: 'premiumCredit' },
          { text: 'Bulk Subscriber(s)', value: 'corp' },
          { text: 'Bulk Credit(s)', value: 'corpCredit' },
          { text: '60 Minutes Total', value: 'basicTotal' },
          { text: 'Premium Total', value: 'premiumTotal' },
          { text: 'Bulk Total', value: 'bulkTotal' },
          { text: 'Total', value: 'monthlyTotal' }
        ];
          // me.headers.unshift({ text: 'Email', value: 'email' });
          // me.headers.push({ text: 'Premium Credit(s)', value: 'premiumCredit' });
          // me.headers.push({ text: 'Bulk Credit(s)', value: 'corpCredit' });
        }
        else{
          me.headers = [
            { text: 'Platforms(s)', value: 'platform' },
            {
              text: 'New Register(s)',
              value: 'registered',
            },
            { text: '60 Minutes', value: 'basicCredit' },
            { text: 'Premium Subscriber(s)', value: 'premium' },
            { text: 'Bulk Subscriber(s)', value: 'corp' }
          ];
        }

        me.results = results.data;
        me.isLoading = false;
      })
    },
    reset(){
      const me = this
      me.results = [];
      me.isLoading = false;
    },
    updateSort(newOptions) {
      if (newOptions.sortBy.length > 0) {
        const index = this.options.sortBy.indexOf(newOptions.sortBy[0]);
        if (index === -1) {
          // If not already sorted, sort descending first
          newOptions.sortDesc = [true];
        } else {
          // Toggle sort order
          newOptions.sortDesc[index] = !this.options.sortDesc[index];
        }
      }
      this.options = newOptions;
    }
  },
  computed: {
    msg() {
      return this.$lang.login.welcome;
    },
  },
  mounted(){
    const me = this
    const today = new Date()
    const monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");

    today.setDate(1);
    for (var i=0; i<=11; i++) {
        const curMonthName = monthName[today.getMonth()];
        const month = ("0" + (today.getMonth() + 1)).slice(-2)
        var yyyy = today.getFullYear()

        me.dates.push({text:`${curMonthName} ${yyyy}`,value:yyyy+month})
        today.setMonth(today.getMonth() - 1);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.affform{
 margin:10px;
 padding:10px;
 border-radius: 10px;
 box-shadow: 0 5px 12px rgba(1, 1, 1, 0.15);
  -webkit-box-shadow: 0 5px 12px rgba(1, 1, 1, 0.15);
  background: white;
  position: relative;
}
.affform img.logo{
  vertical-align: sub;
  height:30px; 
  width: 30px; 
  display: inline-block
}
.affform h1{
  min-width: 100%;
  font-size: 20px;
}
.affform .month-select{
  min-width: 100%;
  padding-top: 10px;
  padding-left: 20px;
}
.affform input{
  display:none;
}
</style>
